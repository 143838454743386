<template>
  <v-sheet
    color="#379C6F"
    class="d-flex align-center justify-center"
    height="100%"
  >
    <v-container my-10>
      <v-row align="center">
        <v-col cols="12" sm="6" md="6" lg="6" align="start">
          <div class="d-flex flex-column justify-space-between align-center">
            <v-img src="@/assets/landing-page/computer1.jpg" max-width="100%" />
          </div>
        </v-col>
        <v-col cols="12" sm="6" md="6" lg="6">
          <div
            class="white--text"
            :class="{
              'text-center': $vuetify.breakpoint.smAndDown,
              'text-start': $vuetify.breakpoint.mdAndUp,
            }"
          >
            <div class="title1" style="line-height: 1">
              A MAIS NOVA SOLUÇÃO PARA OS DESAFIOS DA LEI GERAL DE PROTEÇÃO DE
              DADOS.
            </div>
            <div class="mt-4">
              Você será um dos PIONEIROS a contar com nossa Plataforma Online,
              garantindo CONDIÇÕES INCRÍVEIS e muito mais. Faça parte do nosso
              grupo seleto respondendo ao formulário.
            </div>
          </div>
          <v-form class="mt-4" ref="leadsForm">
            <v-text-field
              label="Nome *"
              placeholder="Nome *"
              v-model="lead.name"
              required
              hide-details="auto"
              solo
              dense
              class="mt-2"
              :rules="[$rules.required]"
              :disabled="loading"
            />
            <v-text-field
              label="Telefone *"
              v-model="lead.phone_number"
              required
              hide-details="auto"
              solo
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.phone]"
              v-mask="['(##) ####-####', '(##) #####-####']"
              placeholder="(00) 00000-0000"
              :disabled="loading"
            />
            <v-text-field
              label="E-Mail *"
              placeholder="E-Mail *"
              v-model="lead.email"
              required
              hide-details="auto"
              solo
              dense
              class="mt-2"
              :rules="[$rules.required, $rules.email]"
              :disabled="loading"
            />
            <v-checkbox
              label="Concordo em Receber comunicações sobre o eConsenso"
              class="white rounded pa-2 mt-2 elevation-2"
              hide-details="auto"
              :rules="[(v) => !!v || 'Concorde para continuar']"
              :disabled="loading"
            />
            <v-btn
              color="primary"
              class="mt-2"
              block
              :loading="loading"
              @click="sendForm()"
            >
              QUERO GARANTIR MINHA VAGA
            </v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <Footer />
  </v-sheet>
</template>

<script>
import Footer from "@/layout/default/Footer.vue";

export default {
  components: {
    Footer,
  },
  data: () => ({
    loading: false,
    lead: {
      name: "",
      email: "",
      phone: "",
    },
  }),

  methods: {
    sendForm() {
      if (this.$refs.leadsForm.validate()) {
        this.loading = true;
        const queryParams = Object.entries(this.$route.query).map(([key, val]) => `${key}=${val}`)
        this.$axios
          .post("/lead", {
            ...this.lead,
            stage: "Pré-Lançamento",
            tags: ["LP Pré-Lançamento", ...queryParams],
          })
          .then(() => {
            this.$showSuccessMessage(
              "Agradecemos pelo seu interesse",
              "Em muito breve você receberá um e-mail com mais informações, e por lá manteremos contato. Até mais!"
            );
            this.$refs.leadsForm.reset();
          })
          .catch(() => {
            this.$showMessage(
              "error",
              "Ocorreu um erro inesperado ao salvar os dados. Tente novamente ou contate nosso suporte em contato@econsenso.com.br"
            );
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$showMessage(
          "warning",
          "Por favor, preencha os campos obrigatórios"
        );
      }
    },
  },
};
</script>

<style scoped>
.title1 {
  font-size: 34px;
  font-weight: bold;
}
</style>